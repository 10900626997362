<template>
  <gf-base v-loading="loading">
    <el-dialog title="Delete Product" :visible.sync="deleteModalVisible" append-to-body v-loading="deleteModalLoading" :close-on-click-modal="false">
      <gf-alert>
        <template #icon>
          <span class="svg-icon svg-icon-danger svg-icon-xxl">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24"/>
                <path d="M11.1669899,4.49941818 L2.82535718,19.5143571 C2.557144,19.9971408 2.7310878,20.6059441 3.21387153,20.8741573 C3.36242953,20.9566895 3.52957021,21 3.69951446,21 L21.2169432,21 C21.7692279,21 22.2169432,20.5522847 22.2169432,20 C22.2169432,19.8159952 22.1661743,19.6355579 22.070225,19.47855 L12.894429,4.4636111 C12.6064401,3.99235656 11.9909517,3.84379039 11.5196972,4.13177928 C11.3723594,4.22181902 11.2508468,4.34847583 11.1669899,4.49941818 Z" fill="#000000" opacity="0.3"/>
                <rect fill="#000000" x="11" y="9" width="2" height="7" rx="1"/>
                <rect fill="#000000" x="11" y="17" width="2" height="2" rx="1"/>
              </g>
            </svg>
          </span>
        </template>
        You are trying to delete this product. All existing variant under this product will also be deleted. This action cannot be undone. Are you sure?
      </gf-alert>
      <template #footer>
        <CloseButton class="mr-2" @click="deleteModalVisible = false" />
        <DeleteButton @click="remove"/>
      </template>
    </el-dialog>
    <gf-card :tab="tabs" :active-tab.sync="active" @tab-click="tabClick">
      <template #toolbar>
        <DeleteButton class="mr-2" @click="deleteModalVisible = true"/>
        <BackButton @click="back"/>
      </template>
      <router-view :active.sync="active" :product="product"></router-view>
    </gf-card>
  </gf-base>
</template>

<script>
import ProductService from '@/services/v1/Product'

// Components Import
import CloseButton from '@/component-v2/CloseButton'
import DeleteButton from '@/component-v2/DeleteButton'
import BackButton from '@/component-v2/BackButton'

export default {
  components: {
    CloseButton,
    DeleteButton,
    BackButton
  },
  data () {
    return {
      active: 1,
      tabs: [
        { id: 1, label: 'Details', routeName: 'product-detail' }
      ],

      deleteModalVisible: false,
      deleteModalLoading: false,
      loading: false,

      product: {
        is_manufactured: null
      }
    }
  },
  methods: {
    back () {
      this.$router.push({ name: 'product-list' })
    },
    async remove () {
      try {
        this.deleteModalLoading = true
        const pdService = new ProductService(this.$route.params.id)
        await pdService.del()

        this.deleteModalVisible = false
        this.$message.success('Successfully deleted product')
        await this.$router.push({ name: 'product-list' })
      } catch (error) {
        this.$Error(error)
      } finally {
        this.deleteModalLoading = false
      }
    },
    tabClick (tab) {
      this.$router.push({ name: this.tabs[tab - 1].routeName })
    },

    async getProduct () {
      try {
        this.loading = true
        const pdService = new ProductService(this.$route.params.id)
        const response = await pdService.get()
        this.product = response.data

        if (!this.product.image_url) {
          this.product.image_url = '/default/material-icon.jpg'
        }
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    }
  },
  async mounted () {
    await this.getProduct()

    // Set Tab values
    if (this.product.is_manufactured) {
      this.tabs.push({ id: 2, label: 'Materials', routeName: 'product-materials' })
    } else {
      this.tabs.push({ id: 2, label: 'Variants', routeName: 'product-variants' })
    }
  }
}
</script>
